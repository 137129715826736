import Axios from 'axios';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Col, Form } from "reactstrap";
import {
    placeOrder,
    processingPayment,
    errorProcessingPayment,
    sendEmailReport,
    validateCardInput,
    changeAppleGooglePayAvailability
} from "../../../../actions/index";
import { isAndroid, isIOS, isMacOs } from 'mobile-device-detect';
import i18n from "../../../../i18next";

import StripeAppleGooglePayForm from "./StripeAppleGooglePayForm";
import { ARABIC, SERVER_URL } from '../../../../utils/Constants';
import { deviceSupportApplePay, initiateApplePayOption } from "../../../../pwa-app/utils/ApplePayUtils";
import { deviceSupportGooglePay, initiateGooglePayOption } from "../../../../pwa-app/utils/GooglePayUtils";
import {getQueryInfoFromUrl, isCardPaymetnAvailable} from '../../../../utils/CommonUtils';
import { sendMessageToParentSite } from '../../../../utils/CrossOriginUtils';

class StripeForm extends Component {
    constructor(props) {
        super(props);
        const userAgent = window.navigator.userAgent;
        let userOnMobileIos = userAgent.match(/iPhone; CPU iPhone OS/) || userAgent.match(/CPU iPhone/) || userAgent.match(/iPad;/);

        this.state = {
            userOnMobileIos: userOnMobileIos,
            isCardValid: false,
            borderColor: '',
            cardEmpty: true,
            error: undefined,
            cardElement: null,
            isSaveCardAllowed: false,
            stripeAlreadyCalled: false,
            stripeClientSideAlreadyCalled: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePaymentPWA = this.handlePaymentPWA.bind(this);
        this.handleCardElementChange = this.handleCardElementChange.bind(this);
        this.clickedOutsideCardElement = this.clickedOutsideCardElement.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    componentDidMount() {
        this.applyTheme();
        let isIntegration = getQueryInfoFromUrl().brandId;
        // Event listeners for clicks outside the card field
        // required to complete Stripe's CardElement validation
        // currently CardElement was missing validation for empty CVC and PostCode
        if (this.state.userOnMobileIos) {
            this.iosDevicesListener();
        }

        document.addEventListener('click', this.clickedOutsideCardElement, false);

        window.addEventListener('message', this.appleGooglePayMessageListener, false);

        if (this.props.payment.selectedPaymentCard.paymentCardId) {
            this.setState({
                ...this.state,
                isCardValid: true
            }, () => this.props.validateCardInput(true));
        }

        if (isIntegration) {
            sendMessageToParentSite('isStripeReady');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // These lines of code work correctly even in case of no selected saved card because the flag has already been changed in componentDidMount
        const { selectedPaymentCard } = this.props.payment;
        const isPayWithCardAvailable = isCardPaymetnAvailable(this.props.payment.paymentTypes);

        if (selectedPaymentCard.paymentCardId !== prevProps.payment.selectedPaymentCard.paymentCardId
            && this.state.cardEmpty) {

            this.setState({
                ...this.state,
                isCardValid: selectedPaymentCard.paymentCardId ? true : false
            }, () => this.props.validateCardInput(this.state.isCardValid));
        }

        if (selectedPaymentCard.paymentCardId && this.state.isSaveCardAllowed) {
            this.setState({
                ...this.state,
                isSaveCardAllowed: false
            });
        }

        if (this.props.stripe
            && !this.state.stripeAlreadyCalled
            && !this.props.isAppleGooglePayAvailable
            && (isAndroid || isIOS || isMacOs)
            && isPayWithCardAvailable
            || prevState.stripeClientSideAlreadyCalled !== this.state.stripeClientSideAlreadyCalled) {
            this.setState(
                {
                    ...this.state,
                    stripeAlreadyCalled: true
                },
                () => this.callStripeForAppleGooglePay()
            )
        }
    }

    componentWillUnmount() {
        // Unmounts the event listeners added for Stripe's CardElement validation
        if (this.state.userOnMobileIos) {
            this.iosDevicesListener(true);
        } else {
            document.removeEventListener('click', this.clickedOutsideCardElement, false);
        }

        window.removeEventListener('message', this.appleGooglePayMessageListener, false);
    }

    callStripeForAppleGooglePay = () => {
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        const amount = shoppingCartTotalWithGiftCard ? Number((shoppingCartTotalWithGiftCard * 100).toFixed(0)) : Number((this.props.shoppingCart.total * 100).toFixed(0));
        const countryCode = this.props.brand.countryCode;
        const restaurantCurrency = this.props.selectedRestaurant.currency;
        let isIntegration = getQueryInfoFromUrl().brandId;

        if (this.props.pwaAppRunning) {
            if (isIOS) {
                deviceSupportApplePay((result) => {
                    this.props.changeAppleGooglePayAvailable(!!result);
                })
            } else if (isAndroid) {
                deviceSupportGooglePay((result) => {
                this.props.changeAppleGooglePayAvailable(!!result);
                });
            }
        } else {
            if (isIntegration && this.state.stripeClientSideAlreadyCalled && (isAndroid || isIOS || isMacOs)) {
                const { stripePremenuData } = this.props.paymentProvider;
                const orderData = {
                    country: countryCode,
                    currency: restaurantCurrency,
                    total: {
                        label: 'Total',
                        amount: amount
                    },
                };

                let applePayObject = {
                    method: 'appleGooglePay',
                    key: {
                        apiKey: stripePremenuData.publicKey,
                        orderData
                    }
                }

                sendMessageToParentSite(applePayObject);
            } else if (!isIntegration) {
                const paymentRequest = this.props.stripe.paymentRequest({
                    country: countryCode,
                    currency: restaurantCurrency,
                    total: {
                        label: 'Total',
                        amount: amount
                    },
                });
                paymentRequest.canMakePayment().then((result) => {
                    this.props.changeAppleGooglePayAvailable(!!result);
                });
            }
        }
    }

    appleGooglePayMessageListener = (e) => {
        if (e.data === 'stripeIsReady') {
            this.setState({ ...this.state, stripeClientSideAlreadyCalled: true })
        } else if (e.data.appleGooglePayAvailable) {
            this.props.changeAppleGooglePayAvailable(e.data.appleGooglePayAvailable);
        } else if (e.data.paymentMethod) {
            e.data.complete = () => { };
            this.handleSubmit(e.data);
            window.removeEventListener('message', this.appleGooglePayMessageListener, false);
        }
    }

    // The method containing all the iOS listeners (mobile Safari does not have click event)
    iosDevicesListener(removeListener) {
        if (!removeListener) {
            document.addEventListener('touchstart', this.clickedOutsideCardElement, false);
            document.addEventListener('touchend', this.clickedOutsideCardElement, false);
            document.addEventListener('touchcancel', this.clickedOutsideCardElement, false);
            document.addEventListener('touchmove', this.clickedOutsideCardElement, false);
        } else {
            document.removeEventListener('touchstart', this.clickedOutsideCardElement, false);
            document.removeEventListener('touchend', this.clickedOutsideCardElement, false);
            document.removeEventListener('touchcancel', this.clickedOutsideCardElement, false);
            document.removeEventListener('touchmove', this.clickedOutsideCardElement, false);
        }
    }

    async handleSubmit(e) {
        const { isAppleGooglePaySelected } = this.props;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        
        !isAppleGooglePaySelected && e.preventDefault(); // Prevents the default page refreshing behaviour of the form
        const { restaurantId } = this.props.selectedRestaurant;
        if (this.props.payment.selectedPaymentType.value == 'Credit card' || isAppleGooglePaySelected) {
            if (this.props.payment.selectedPaymentCard !== undefined && this.props.payment.selectedPaymentCard !== null) {

                const { stripe, customer, selectedRestaurant, shoppingCart, payment } = this.props;
                let methodForPayment = null;

                this.props.processingPayment();
                let errorCatched = false;
                if (this.state.cardElement && !payment.selectedPaymentCard.paymentCardId && !isAppleGooglePaySelected) {
                    // let cardElement = `sjdhajshdajhsdkjh`; // - for testing purposes
                    if (!navigator.onLine) {
                        return this.props.errorProcessingPayment('error', 'OFFLINE', this.props.paymentProvider.providerType.id);
                    }

                    methodForPayment = await stripe.createPaymentMethod('card', this.state.cardElement)
                        .then(result => {
                            // Errors when cardElement is an object - normal errors returned from stripe
                            if (result.error) {
                                if (!navigator.onLine) {
                                    this.props.errorProcessingPayment('error', 'OFFLINE', this.props.paymentProvider.providerType.id);
                                    errorCatched = true;
                                } else if (navigator.onLine) {
                                    errorCatched = true;
                                    this.props.sendEmailReport(restaurantId, result.error, 'createPaymentMethod', 'cardObject');
                                    this.props.errorProcessingPayment(result.error, 'createPaymentMethod', this.props.paymentProvider.providerType.id);
                                }
                            }

                            if (navigator.onLine) {
                                return result.paymentMethod;
                            }

                        }) // Exeptional errors when cardElement is messed up for some reason and not in the right format
                        .catch(error => {
                            if (!navigator.onLine) {
                                errorCatched = true;
                                this.props.errorProcessingPayment('error', 'OFFLINE', this.props.paymentProvider.providerType.id);
                                return;
                            } else if (navigator.onLine) {
                                errorCatched = true;
                                this.props.sendEmailReport(restaurantId, error, 'createPaymentMethod', 'cardObject');
                                this.props.errorProcessingPayment(error, 'createPaymentMethod', this.props.paymentProvider.providerType.id);
                            }
                        });
                }
                // Whenever error is catched stops the execution of the below code
                if (errorCatched) return;
                let paymentIntentRequest = {}
                if (isAppleGooglePaySelected) {
                    paymentIntentRequest = {
                        customerId: customer.id,
                        restaurantId: selectedRestaurant.restaurantId,
                        amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
                        amountWithoutServiceFee: shoppingCart.subtotal,
                        serviceFee: shoppingCart.serviceFee,
                        currency: selectedRestaurant.currency,
                        stripeAuthorizeRequest: {
                            paymentMethod: e.paymentMethod.id,
                            newCard: false
                        }
                    }
                } else {
                    paymentIntentRequest = {
                        customerId: customer.id,
                        restaurantId: selectedRestaurant.restaurantId,
                        amount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total,
                        amountWithoutServiceFee: shoppingCart.subtotal,
                        serviceFee: shoppingCart.serviceFee,
                        currency: selectedRestaurant.currency,
                        stripeAuthorizeRequest: {
                            paymentMethod: methodForPayment ? methodForPayment.id : payment.selectedPaymentCard.paymentCardId,
                            newCard: payment.selectedPaymentCard.paymentCardId || !this.state.isSaveCardAllowed ? false : true
                        }
                    }
                }

                const header = { headers: { 'Authorization': `TOKEN${customer.token}` } };

                let clientSecret = null, paymentIntentID = null;

                if (!navigator.onLine) {
                    return this.props.errorProcessingPayment('error', 'OFFLINE', this.props.paymentProvider.providerType.id);
                }

                await Axios.post(`${SERVER_URL}/payment/authorize`, paymentIntentRequest, header)
                    .then(response => {
                        clientSecret = response.data.stripeResponse.clientSecret;
                        paymentIntentID = response.data.stripeResponse.paymentIntentId;
                    })
                    .catch(error => {
                        isAppleGooglePaySelected && e.complete('fail');
                        if (!navigator.onLine) {
                            this.props.errorProcessingPayment('error', 'OFFLINE', this.props.paymentProvider.providerType.id);
                        } else if (navigator.onLine) {
                            this.props.errorProcessingPayment(error, 'payment-intent', this.props.paymentProvider.providerType.id);
                            this.props.sendEmailReport(restaurantId, error, 'paymentIntentFailure');
                        }
                    });

                let secondParam;
                if (isAppleGooglePaySelected) {
                    secondParam = { payment_method: e.paymentMethod.id }
                } else {
                    secondParam = !payment.selectedPaymentCard.paymentCardId ? this.state.cardElement : { "payment_method": `${payment.selectedPaymentCard.paymentCardId}` };
                }

                if (!navigator.onLine) {
                    return this.props.errorProcessingPayment('error', 'OFFLINE', this.props.paymentProvider.providerType.id);
                }

                if (clientSecret) {
                    const { paymentIntent, error } = await stripe.handleCardPayment(
                        clientSecret,
                        secondParam
                    );
                    if (error) {// Handles payment error returned from Stripe
                        if (error.code === 'payment_intent_unexpected_state') { // This particular case happens when somehow handleCardPayment is called twice from the apps
                            this.props.placeOrder(isAppleGooglePaySelected, paymentIntentID, e.complete);
                            this.props.sendEmailReport(restaurantId, error, 'payment_intent_unexpected_state');
                            this.props.changeAppleGooglePayAvailability(null);
                        } else {
                            isAppleGooglePaySelected && e.complete('fail');
                            this.props.sendEmailReport(restaurantId, error, 'handleCardPayment');
                            this.props.errorProcessingPayment(error, '', this.props.paymentProvider.providerType.id);
                        }
                    } else if (paymentIntent && paymentIntent.status === "succeeded") {// Handle payment success
                        // this.props.placeOrder(isAppleGooglePaySelected, paymentIntent.id, e.complete);
                        // this.props.changeAppleGooglePayAvailability(null);
                        this.props.sendEmailReport(restaurantId, null, null, null, true);
                    } else if (paymentIntent && paymentIntent.status === "requires_capture") {
                        this.props.placeOrder(isAppleGooglePaySelected, paymentIntent.id, e.complete);
                        this.props.changeAppleGooglePayAvailability(null);
                    } else {
                        isAppleGooglePaySelected && e.complete('fail');
                        this.props.sendEmailReport(restaurantId, `UnknownError__paymentIntent: ${paymentIntent}__error: ${error}`, 'noCase');
                        this.props.errorProcessingPayment();
                    }
                } else {
                    // Commented out because it duplicates the emails when backend API fails with creating payment-intent
                    // this.props.sendEmailReport(restaurantId, `NO ClientSecret__clientSecret: ${clientSecret}`, 'noCase');
                }
            }
        } else {
            this.props.sendEmailReport(restaurantId, `selectedPaymentType: ${this.props.payment.selectedPaymentType}`, 'noCardSelected');
        }
        return false;
    }

    handlePaymentPWA() {
        const { isAppleGooglePaySelected, paymentProvider, shoppingCart, brand, selectedRestaurant } = this.props;
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;

        // const amount = Number((shoppingCart.total * 100).toFixed(0));
        const orderData = {
            brand: brand,
            restaurant: selectedRestaurant,
            totalAmount: shoppingCartTotalWithGiftCard ? shoppingCartTotalWithGiftCard.toFixed(2) : shoppingCart.total
        };

        if (isIOS) {
            initiateApplePayOption(paymentProvider, orderData, (stripeToken) => {
                this.props.placeOrder(isAppleGooglePaySelected, stripeToken);
            })
        }

        if (isAndroid){
            initiateGooglePayOption(paymentProvider, orderData, (stripeToken) => {
                this.props.placeOrder(isAppleGooglePaySelected, stripeToken);
            })
        }
    }

    renderAppleGooglePayForm(isAppleGooglePaySelected) {
        const shoppingCartTotalWithGiftCard = this.props.giftCards.giftCards ? this.props.giftCards.giftCards.total : null;
        return (
            <StripeAppleGooglePayForm
                isAppleGooglePaySelected={isAppleGooglePaySelected}
                stripeClientSideAlreadyCalled={this.state.stripeClientSideAlreadyCalled}
                handleSubmit={this.handleSubmit}
                handlePayment={this.handlePaymentPWA}
                shoppingCartTotalWithGiftCard={shoppingCartTotalWithGiftCard} {...this.props}
            />
        )
    }

    handleCardElementChange(e) {
        // cardEmpty, error and clicked outside are set to state
        // because all are needed for completing validation of the
        // CVC and PostCode of Stripe's CardElement
        this.setState({
            ...this.state,
            isCardValid: this.props.payment.selectedPaymentCard.brand && e.empty ? true : e.complete,
            cardEmpty: e.empty,
            error: this.props.payment.selectedPaymentCard.brand && e.empty ? false : e.error,
            clickedOutside: false
        }, () => {
            if (!e.empty && this.props.payment.selectedPaymentCard.brand) {
                this.props.clearPaymentCard();
            }
            this.props.validateCardInput(this.state.isCardValid);
        });
    }

    // Click event listener for android devices and browsers
    clickedOutsideCardElement(e) {
        const cardElementWrapper = document.getElementById('card-element');
        const cardElement = document.getElementById('cardElement');

        let targetElement = e.target;

        do {
            if (targetElement == cardElementWrapper || targetElement == cardElement) {
                // console.log('clicked inside ');
                this.setState({
                    ...this.state,
                    clickedOutside: false
                });
                return;
            }
            targetElement = targetElement.parentNode;
        } while (targetElement);

        if (!this.state.isCardValid) {
            this.setState({
                ...this.state,
                clickedOutside: true
            });
        }
    }

    handleReady = element => {
        this.setState({
            ...this.state,
            cardElement: element
        });
    };

    changeState({ target }) {
        this.setState({
            ...this.state,
            isSaveCardAllowed: !this.state.isSaveCardAllowed
        });
    }

    applyTheme() {
        let customizedStyles = JSON.parse(JSON.stringify(styles));
        const { inputsFontColor, inputsPlaceholderColor } = this.props.customerThemes.selectedTheme;
        customizedStyles.cardElementStyle.base.color = inputsFontColor;
        customizedStyles.cardElementStyle.base['::placeholder'] = { color: inputsPlaceholderColor };
        styles = customizedStyles;
        return styles;
    }

    render() {
        const { cardElementStyle, stripeElementContainer } = styles;
        const { primaryFontColor } = this.props.customerThemes.selectedTheme;
        const isAppleGooglePaySelected = this.props.isAppleGooglePaySelected;
        // Selects the border style of "card-element" according to whether CVC and PostCode are entered - completes Stripe validation
        const cardElValidationStyle = !this.state.cardEmpty && this.state.clickedOutside && !this.state.error ? { border: '1px solid red' } : { border: '1px solid lightgrey' };
        const isArabic = this.props.language === ARABIC;

        return (
            <React.Fragment>
                <Col xs={12} sm={12} md={8} style={stripeElementContainer} hidden={this.props.paymentWithCard || isAppleGooglePaySelected}>
                    <Form onSubmit={this.handleSubmit} hidden={isAppleGooglePaySelected} id="payment-form" >

                        <div className="checkout" id="card-element" style={cardElValidationStyle}>
                            <CardElement id="cardElement" style={cardElementStyle} onChange={(e) => this.handleCardElementChange(e)} onReady={this.handleReady.bind(this)} />
                        </div>
                        {
                            !this.state.isCardValid && !this.state.cardEmpty && this.state.clickedOutside && this.state.error === undefined &&
                            <div className='postalCodeMsg' style={{ fontSize: 14, color: '#a00' }}>{i18n.t('screens:stripeForm.stripeErrMissingFields')}</div>
                        }
                        <label htmlFor='isSaveCardAllowed' className='marketingCheckbox-label' style={{ margin: isArabic ? '5px 22px auto auto' : '5px auto auto 22px', color: primaryFontColor, fontSize: 16, lineHeight: '33px', minWidth: '90%' }}>
                            <span style={isArabic ? { marginRight: 10 } : { marginLeft: 10 }}>{i18n.t('screens:checkoutScreen.saveCardMsg')}</span>
                            <input
                                type='checkbox' name='isSaveCardAllowed' id='isSaveCardAllowed' style={{ marginTop: 5 }}
                                checked={this.state.isSaveCardAllowed}
                                onChange={this.changeState} disabled={this.props.payment.selectedPaymentCard.paymentCardId} />
                            <span className='marketingCheckbox-custom'></span>
                        </label>

                    </Form>
                </Col>
                {this.props.isAppleGooglePayAvailable ? this.renderAppleGooglePayForm(isAppleGooglePaySelected) : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        brand: state.brand,
        customer: state.customer,
        payment: state.payment,
        selectedRestaurant: state.selectedRestaurant,
        orderTypes: state.orderTypes,
        shoppingCart: state.shoppingCart,
        menuPreviewMode: state.menuPreviewMode.previewMode,
        customerThemes: state.customerThemes,
        pwaAppRunning: state.pwaAppRunning
    };
};

export default connect(mapStateToProps, { placeOrder, processingPayment, errorProcessingPayment, sendEmailReport, validateCardInput, changeAppleGooglePayAvailability })(injectStripe(StripeForm));

let styles = {
    cardElementStyle: {
        base: {
            fontSize: '16px',
        },
        backgroundColor: 'red'
    },
    stripeElementContainer: {
        margin: 'auto',
        paddingBottom: 10
    },
    imageStyle: {
        paddingRight: 18,
        marginTop: 0
    },
    profileNameStyle: {
        marginLeft: -10,
        verticalAlign: 'middle',
        fontSize: '1.0625rem'
    }
}